Vue.component('location-update-heatmap-settings', {
    props: ['listingId'],
    data: function() {
        return {
            isLoading: true,
            form: new SparkForm({
                auto: true,
                grid_size: '9x9',
                grid_units: 'miles',
                spacing: '0.25'
            })
        };
    },

    created() {
        axios.get('/location/' + this.listingId + '/heatmap-settings')
            .then(response => {
                Object.assign(this.form, response.data);
                this.isLoading = false;
            })
            .catch(error => {
                console.error('Error fetching heatmap settings for listing:', error);
                this.isLoading = false;
            });
    },

    methods: {
        update() {
            axios.post('/location/' + this.listingId + '/update-heatmap-settings', this.form)
                .then(response => {
                    this.form.successful = true;
                })
                .catch(error => {
                    this.form.errors.set(error.response.data.errors);
                });
        }
    }
});
